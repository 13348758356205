import React from "react";
const FormatTwo = ({ details }) => {
  const generateTable = (shareholders, header) => {
    if (!shareholders || shareholders.length === 0) {
      return null;
    }

    const headers = Object.keys(shareholders[0]);
    const hyper_links = [
      "link",
      "report",
      "data_source",
      "links",
      "rating_rationale",
      "timeline_link",
      "FY_link",
    ];

  
    return (
      <div>
        <div key={header}>
          <div className="card-title">{header.replace(/_/g, " ")}</div>
          <table className="mb-2 table table-striped responsive">
            <thead>
            <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {shareholders.map((shareholder, rowIndex) => (
                <tr key={rowIndex}>
                  {headers.map((header, colIndex) => (
                    <td key={colIndex}>
                      {hyper_links.includes(header) ? (
                        shareholder[header] ? (
                          <a href={shareholder[header]} target="_blank" rel="noopener noreferrer">
                            <i className="fas fa-external-link-alt"></i>
                          </a>
                        ) : (
                          <span>No Link Available</span> 
                        )
                      ) : (
                        shareholder[header]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div>
      {details && details.length > 0 ? (
        details.map((group, groupIndex) => 
          (
          <div key={groupIndex}>
            {Object.entries(group).map(([key, value], index) =>
              value && Array.isArray(value) ? generateTable(value, key) : null
            )}
          </div>
        ))
      ) : (
        <p>No Data Available</p>
      )}
    </div>
  );
};

export default FormatTwo;
